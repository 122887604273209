import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import Icon from "../Icon"
import { MapStoreProps } from "../../props/StoresProps"

const InfoWindow = styled.div`
  ${tw`relative bottom-27-2 -left-11 w-25 h-22 bg-lightest z-50 flex items-center justify-center flex-col`} ::after {
    content: "";
    transform: translate(0, -50%) rotate(45deg);
    ${tw`absolute w-1-6 h-1-6 bg-lightest z-50 -bottom-1-6`}
  }
`

const Title = tw.h3`
  text-18 font-medium leading-1.56
`

const Text = tw.p`
  text-16 leading-1.56
`
const Direction = tw.a`text-18 leading-1.56 underline mt-1-6`

const MapMarker = ({ store }) => {
  return (
    <>
      <Icon name={`bared`} size={`small`} />
      {store.showInfoWindow && (
        <InfoWindow>
          <Title>{store.title}</Title>
          <Text>{store.address}</Text>
          <Text>{`${store.city}, ${store.state} ${store.postcode}`}</Text>
          <Direction
            href={`https://maps.google.com/maps?q=${store.address} ${store.city} ${store.state} ${store.postcode}`}
            target={`_blank`}
            rel={`noopener noreferrer`}
          >
            Get direction &gt;
          </Direction>
        </InfoWindow>
      )}
    </>
  )
}

MapMarker.propTypes = {
  store: PropTypes.shape(MapStoreProps),
}

export default MapMarker

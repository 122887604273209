import React from "react"
import { graphql } from "gatsby"

import { Stores as Page } from "../components/Stores/Stores"

export const query = graphql`
  query {
    page: sanityPageStores {
      title
      stores {
        name
        sections {
          title
          phone
          address
          city
          state
          postcode
          location {
            lat
            lng
          }
          openHours {
            day
            hours
          }
        }
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />

import React, { useState } from "react"
import GoogleMapReact from "google-map-react"

import { MapStoresProps } from "../../props/StoresProps"
import { GOOGLE_MAP_STYLE } from "./MapStyle"
import MapMarker from ".//MapMarker"
import config from "../../../config"

const Map = ({ mapStores }) => {
  const location = { lat: -37.82398, lng: 144.99099 }
  const initialState = mapStores.map(store => {
    return {
      ...store,
      showInfoWindow: false,
    }
  })
  const [storesData, setStoresData] = useState(initialState)
  const handleMarkerClick = key => {
    setStoresData(prevStoresData => {
      const index = prevStoresData.findIndex(e => e.id === key.toString())
      const newStoresData = [...prevStoresData]
      newStoresData[index].showInfoWindow = !prevStoresData[index].showInfoWindow
      return newStoresData
    })
  }
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: config.googleMapsAPIKey }}
      defaultCenter={location}
      defaultZoom={13}
      options={{
        scrollwheel: false,
        styles: GOOGLE_MAP_STYLE,
      }}
      onChildClick={handleMarkerClick}
    >
      {storesData.map(store => (
        <MapMarker key={store.id} lat={store.location.lat} lng={store.location.lng} store={store} />
      ))}
    </GoogleMapReact>
  )
}

Map.propTypes = {
  mapStores: MapStoresProps,
}

export default Map

import React from "react"

export const withStores = Component => ({ name = "Stores", page, ...props }) => {
  const { title, stores } = page

  let storesList = []

  stores.forEach(store => {
    storesList.push(...store.sections)
  })

  const mapStores = storesList.map((store, index) => ({
    ...store,
    id: String(index + 1),
  }))

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: title,
    },
  ]

  Component.displayName = name
  return <Component {...props} title={title} paths={paths} stores={stores} mapStores={mapStores} />
}

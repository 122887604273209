import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withStores } from "./withStores"
import PageTitle from "../PageTitle/PageTitle"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import StoresDetail from "./StoresDetail"
import Map from "../Map/Map"
import { StoresProps, MapStoresProps } from "../../props/StoresProps"

const Container = styled(StyledContainer)`
  ${tw`flex flex-wrap py-8`}
`

const DetailsWrapper = tw.div`w-full lg:w-1/2 flex items-center justify-center px-2-4`

const MapWrapper = tw.div`w-full hidden md:block lg:w-1/2 min-h-66`

export const Stores = withStores(({ paths, title, stores, mapStores }) => {
  return (
    <>
      <PageTitle title={title} paths={paths} />
      <BackgroundContainer background={`light`}>
        <Container>
          <DetailsWrapper>
            <StoresDetail stores={stores} />
          </DetailsWrapper>
          <MapWrapper>
            <Map mapStores={mapStores} />
          </MapWrapper>
        </Container>
      </BackgroundContainer>
    </>
  )
})

Stores.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  stores: StoresProps,
  mapStores: MapStoresProps,
}

export default Stores
